<template>
  <div class="auth">
    <div class="auth__header">
      <a href="https://leasing-trade.ru" class="auth__header__link" target="_blank">Вернуться на сайт</a>
      <div class="auth__logo">
        <img src="/logo.png" alt="Лизинг трейд"/>
      </div>
      <a href="https://leasing-trade.ru" class="auth__header__link auth__header__link--hidden">Вернуться на сайт</a>
    </div>
    <div class="auth__body">
      <form class="auth__form" @submit="onSubmitResend">
        <h1 class="auth__form__title">Сброс пароля</h1>
        <div class="auth__form__input">
          <label for="email">E-mail</label>
          <input type="email" v-model="email" id="email" placeholder="E-mail">
        </div>
        <div class="auth__form__actions">
          <button class="btn" @click="resendMail" type="button">Отправить</button>
          <router-link :to="{ name: 'auth' }">Вход</router-link>
        </div>
      </form>
      <div class="auth__body__back">
        <AuthBack/>
      </div>
    </div>
  </div>
</template>

<script>
import RESEND_MAIL from '../graphql/mutations/ResendMail.gql'
import GraphQLHelper from '../helpers/GraphQLHelper'
import AuthBack from '../components/svg/AuthBack'

const _graphQlHelper = new GraphQLHelper()

export default {
  name: 'Resend',
  data: () => {
    return {
      email: ''
    }
  },
  components: {
    AuthBack
  },
  methods: {
    onSubmitResend (event) {
      event.preventDefault()
      this.resendMail()
    },
    async resendMail ({ target }) {
      if (!target.validity.valid) return

      if (!this.email) {
        this.$notify({
          group: 'lsg-notify',
          text: 'Введите email для сброса пароля.'
        })
      }
      await this.$apollo.mutate({
        mutation: RESEND_MAIL,
        variables: {
          email: this.email
        },
        update: (store, { data: { resendMail } }) => {
          if (resendMail) {
            this.$notify({
              group: 'lsg-notify',
              text: 'Для завершения сброса пароля, проверьте почтовый адрес - ' + this.email
            })
          } else {
            this.$notify({
              group: 'lsg-notify',
              text: 'Почтовый ящик является недействительным - ' + this.email
            })
          }
        }
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
      })
    }
  }
}
</script>
